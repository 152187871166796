import { ConversationCreateModel } from '@shared/models/conversation-create.model';
import { LinkModel } from '@shared/models';

export interface OpportunityCreateModel extends ConversationCreateModel {
  applicationId: string;
  declineReason: string;
  expiryDate: string;
  expectedValue: number;
  opportunityProbability: string;
  expectedCloseDate: string;
  campaignId: number;
  assigneeId: string;
  referrers: string[] | null;
}
