<div class="email-template-container" *ngIf="TemplateType === 'Email'">
  <div class="title">Email Template</div>
  <div class="member-error" *ngIf="memberError">
    Not all linked members have an email address. Fill in the information and try again.
  </div>
  <div class="sub-title" *ngFor="let template of templates">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <i *ngIf="template.sendDate" class="icon-envelope-small"></i>
        <i *ngIf="!template.sendDate" class="icon-email"></i>
        <span *ngIf="template.label" class="label">{{template.label}}</span>
        <span *ngIf="template.name">{{template.name}} - {{template.subject}}</span>
      </div>

      <div class="send-button" *ngIf="mode == 'view'">
        <div *ngIf="template.sendDate" class="send-date">{{ template.sendDate }}</div>
        <cm-button
          *ngIf="!template.sendDate"
          color="secondary"
          [disabled]="isSendDisabled"
          (click)="send(template)"
        >Send
        </cm-button>
      </div>
      <div class="send-button" *ngIf="mode == 'edit'">
        <div><i class="icon-trash" (click)="remove(template)"></i></div>
      </div>
    </div>
  </div>
  <div  *ngIf="mode == 'view' && memberlinks.length === 0" style="margin-top: 16px;">
    No Linked Members Found
  </div>

    <ul>
      <li *ngFor="let item of memberlinks; let i = index">
        <input
          type="checkbox"
          [checked]="i === 0 ? true : item.checked"
          [id]="'checkbox-' + item.email"
          (change)="onCheckboxChange(item)"
          [disabled]="item.isEmailSent && isSentclicked"
        />
        <label [for]="'checkbox-' + item.email">
           {{ item.isEmailSent && item.checked && isSentclicked ? 'Mail Sent To ' : 'Send to ' }}{{ item.firstName || item.organizationName }}

        </label>
    </li>
  </ul>
</div>
<div class="sms-template-container" *ngIf="TemplateType === 'SMS'">
  <div class="title">SMS template</div>
  <div class="member-error" *ngIf="memberError">
    Not all linked members have a phone number. Fill in the information and try again.
  </div>
  <div class="sub-title" *ngFor="let template of templates">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div style="display: flex; align-items: center;">
        <img *ngIf="!template.sendDate" src="../../../../assets/icons/messageSms-icon.svg" alt="" style="width: 15px; margin-right: 8px;">
  <i *ngIf="template.sendDate" class="icon-envelope-small" style="margin-right: 8px;"></i>
  <span *ngIf="template.label" class="label" style="margin-right: 8px;">{{template.label}}</span>
  <span *ngIf="template.name">{{template.name}} </span>
</div>
      <div class="send-button" *ngIf="mode == 'view'">
        <div *ngIf="template.sendDate" class="send-date">{{template.sendDate}}</div>
        <cm-button *ngIf="!template.sendDate" color="secondary" [disabled]="isSendDisabled"
                   (click)="send(template)">Send SMS</cm-button>
      </div>
      <div class="send-button" *ngIf="mode == 'edit'">
        <div><i class="icon-trash" (click)="remove(template)"></i></div>
      </div>
    </div>
  </div>
</div>