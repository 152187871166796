import { TasksDto } from '@core/services/dto';
import { formatDateSimple, formatDateToString } from '@shared/utils';
import { LinkModel } from '@shared/models/link.model';
import { AttachmentModel } from '@shared/models/attachment.model';


export class Task {
  type: string;
  priority: string;
  description: string;
  member?: LinkModel[];
  assigned?: string;
  assigneeId?: string;
  dueDate: string;
  dueDateUTC?: string;
  status: string;
  templateId?: number;
  id: number;
  links: LinkModel[];
  emails?: any[];
  sms?: any[];
  completedDate?: string;
  createdDate?: string;
  createdBy: {
    userId: string;
    firstName: string;
    lastName: string;
  };
  assignee?: {
    firstName: string,
    lastName: string,
    id: string,
    name: string,
    isGroup: boolean,
    status: string,
    type: string
  };
  attachments: AttachmentModel[];
  isWorkflowTask: boolean;
  assignedToEmails?: [];
  conversationType?: number;
  subject?: string;
  templateNameList?: string[];
  previousTemplates?: string[];
  constructor(dto: TasksDto) {
    this.type = dto.type;
    this.priority = dto.priority;
    this.description = dto.description;
    this.member = dto.links;
    // this.assigned = `${dto.assignee.firstName} ${dto.assignee.lastName}`;
    this.dueDate = dto.status === 'Completed' ? dto.dueDate ? formatDateSimple(dto.dueDate) : dto.dueDate
      : dto.dueDate ? formatDateToString(dto.dueDate) : dto.dueDate;
    this.dueDateUTC = dto.dueDate;
    this.createdDate = dto.createdDate ? formatDateSimple(dto.createdDate) : dto.createdDate;
    this.status = dto.status;
    this.id = dto.id;
    this.links = dto.links;
    this.emails = dto.emails;
    this.completedDate = dto.completedDate ? formatDateSimple(dto.completedDate) : dto.completedDate;
    this.createdBy = dto.createdBy;
    this.assignee = dto.assignee;
    this.templateId = dto.templateId;
    this.sms = dto.sms;
    this.attachments = dto.attachments;
    this.isWorkflowTask = dto.isWorkflowTask;
    this.assignedToEmails = dto.assignedToEmails;
    this.conversationType = dto?.conversationType;
    this.subject = dto.subject;
    this.previousTemplates = dto.previousTemplates || [];
    if (dto?.assignee?.isGroup) {
      this.assigneeId = dto?.assignee?.name;
      this.assignee.firstName = dto?.assignee?.name;
      this.assignee.lastName = '';
    }
    else if (!dto?.assignee?.isGroup) {
      this.assigneeId = dto?.assignee?.firstName + ' ' + dto?.assignee?.lastName;
    }
    else {
      this.assigneeId = '';
    }
  }
  name?: string;
}

