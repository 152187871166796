import { LinkDto } from '@core/services/dto/link.dto';
import { ReferrersDto } from '@core/services/dto/referrers.dto';
import { ViewSaleDto } from '@core/services/dto';

export class ViewSaleModel {
  id: number;
  applicationId: string;
  campaign: {
    id: number;
    name: string;
  };
  branchId: number;
  createdDate: string;
  createdBy: {
    userId: string;
    firstName: string;
    lastName: string;
  };
  links: LinkDto[];
  referrers: ReferrersDto[];
  isFromAOSLOS: boolean;

  constructor(dto: ViewSaleDto) {
    this.id = dto.id;
    this.applicationId = dto.applicationId;
    this.campaign = dto.campaign;
    this.branchId = dto.branchId;
    this.createdDate = dto.createdDate;
    this.createdBy = dto.createdBy;
    this.links = dto.links;
    this.referrers = dto.referrers;
    this.isFromAOSLOS = dto.isFromAOSLOS;
  }
}
