import { CommunicationTypeStringEnum } from '@shared/enums/communication-type.enum';
import { InteractionDto } from '@core/services/dto';
import { LinkModel } from '@shared/models/link.model';
import { AttachmentModel } from '@shared/models/attachment.model';
import { formatDateSimple } from '@shared/utils';

export class InteractionModel {
  id?: number;
  conversationId: number;
  conversationType?: string;
  communicationType: string;
  subject: string;
  description: string;
  created?: string;
  member?: LinkModel[];
  startedBy?: string;
  attachments?: AttachmentModel[];

  constructor(dto: InteractionDto) {
    this.id = dto.id;
    this.conversationId = dto.conversationId;
    this.communicationType = dto.communicationType;
    this.conversationType = dto.conversationType;
    this.subject = dto.subject;
    this.description = dto.description;
    this.created =  dto.created;
    this.member = dto.links;
    this.startedBy = `${dto.createdBy?.firstName} ${dto.createdBy?.lastName}`;
    this.attachments = dto.attachments;
  }

  getCommunicationType(): string {
    return CommunicationTypeStringEnum[this.communicationType];
  }
}
