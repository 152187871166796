export const LandingRouterLink = '/dashboard';

export const AllLinks = {
  memberProfile: {
    member: `${LandingRouterLink}/member`,
  },

  profiles: {
    details: (id: number) => `${LandingRouterLink}/profiles/${id}`,
  },

  tasks: {
    index: `tasks`,
    details: (id: number) => `tasks/${id}`,
    edit: (id: number) => `tasks/${id}/edit`
  },

  sale: {
    details: (id: number) => `sales/${id}`
  },

  notes: {
    details: (id: number) => `notes/${id}`
  },


  interactions: {
    details: (id: number) => `interactions/${id}`
  },
  conversations: {
    details: (id: number) => `conversation-detail/${id}`
  },

  account: {
    details: (id: number, type: string, memberId: string) => `member-profile/${memberId}/accounts/${type}/${id}`
  },
};
