import { LinkDto } from '@core/services/dto/link.dto';

export class LinkModel {
  type: string;
  ref: string;
  value?: string;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  accountName?: string;
  conversationSubject?: string;
  opportunitySubject?: string;
  taskType?: string;
  taskDescription?: string;
  noteDescription?: string;
  fileName?: string;
  accountNumber?: string;
  applicationId?: string;
  productName?: string;
  saleName?: string;
  cellPhone?: string;

  constructor(dto: LinkDto) {
    for (const prop in dto) {
      if (dto.hasOwnProperty(prop)) {
        this[prop] = dto[prop];
      }
    }
  }
}
