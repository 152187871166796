import { Injectable } from '@angular/core';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PaginatedResponseDto, SalesDto, UserDto } from '@core/services/dto';
import { SalesApiService } from '@core/services/api.services';
import { GetAllSalesRequestDto } from '@shared/models/requests/get-all-sales.request.dto';
import { SalesModel, SearchUserOptionsModel, ViewSaleModel } from '@shared/models';
import { CreateSalesApiRequest } from '@shared/models/requests/crete-sales.request.dto';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { UserApiService } from '@core/services/api.services/user-api.service';
import { ChangeLogsModel } from '@shared/models/change-logs.model';
import { PageSettings } from '@shared/components/change-logs/models/change-logs.models';
import { DefaultTaskChangeLogsPageSettings } from '@shared/components/task-details/task-details.config';
import { ChangeLogsApiRequest } from '@shared/models/requests/changeLogs.request.dto';

@Injectable({
  providedIn: 'any'
})
export class SalesStateService extends autoUnsubscribeMixin() {
  private changeLogsSubject: BehaviorSubject<PaginatedResponseDto<ChangeLogsModel>> = new BehaviorSubject(null);
  public data: Subject<PaginatedResponseDto<SalesModel>> = new Subject<PaginatedResponseDto<SalesModel>>();
  public detailsSubject: Subject<ViewSaleModel> = new Subject<ViewSaleModel>();
  public currentSales: Subject<SalesModel[]> = new Subject<SalesModel[]>();
  public users: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);
  private salesForOpportunities: Subject<any[]> = new Subject<any[]>();

  private storedFilters: any;
  private _activePage = 0;
  private _pageSize = 10;
  private _updatePage = 0;
  private _sortOrder = 'desc';
  private _sortBy = 'soldDate';

  request: GetAllSalesRequestDto = {
    saleId: null,
    applicationId: null,
    member: null,
    memberId: null,
    productType: null,
    productTypeId: null,
    productName: null,
    productId: null,
    soldBy: null,
    value: null,
    soldFrom: null,
    soldTo: null,
    campaignId: null,
    sortBy: null,
    pageNumber: null,
    pageSize: null,
    sort: null,
  };

  constructor(
    private service: SalesApiService,
    private userService: UserApiService
  ) {
    super();
  }
  public details$: Observable<ViewSaleModel> = this.detailsSubject.asObservable()
    .pipe(
      filter(x => !!x)
    );

  public currentSales$: Observable<SalesModel[]> = this.currentSales.asObservable()
    .pipe(
      filter(x => !!x)
    );
    public changeLogs$: Observable<PaginatedResponseDto<ChangeLogsModel>> = this.changeLogsSubject.asObservable()
    .pipe(
      filter(x => !!x)
    );

  data$(): Observable<PaginatedResponseDto<SalesModel>> {
    return this.data.asObservable();
  }

  users$(): Observable<any> {
    return this.users.asObservable();
  }

  getSales(request: GetAllSalesRequestDto): void {
    this.service.getAllSales(request).pipe(
      map(
        (response: PaginatedResponseDto<SalesDto>) => {
          return {
            ...response,
            data: response.data.map((item: SalesDto) => new SalesModel(item))
          };
        }
      )
    ).subscribe(
      data => {
        this.data.next(data);
      }
    );
  }


  getDetails(id): void {
    this.service.getSale(id).pipe(
      map(sale => new ViewSaleModel(sale))
    ).subscribe(sale => {
      this.detailsSubject.next(sale);
    });
  }




  getSaleProduct(id): void {
    this.request.saleId = id;
    this.service.getAllSales(this.request).pipe(
      map(
        (response: PaginatedResponseDto<SalesDto>) => {
          return {
            ...response,
            data: response.data.map((item: SalesDto) => new SalesModel(item))
          };
        }
      )
    ).subscribe(res => {
      this.currentSales.next(res.data);
    });
  }

  createSale(request: CreateSalesApiRequest): Observable<number> {
    return this.service.createSale(request);
  }

  update(id: number, request: CreateSalesApiRequest): Observable<any> {
    return this.service.updateSale(id, request);
  }

  delete(id: number): Observable<any> {
    return this.service.deleteSale(id);
  }

  getUsers(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: 3, search: searchString };
    return this.userService.getUsers(request);
  }






  getSearchedUsers(search: string): void {
    this.getUsers(search).subscribe(
      res => {
        const userOptions = [];
        res.data.forEach(item => {
          const user = new SearchUserOptionsModel(item);
          userOptions.push(user);
        });
        this.users.next(userOptions);
      }
    );
  }


  getSaleForOpportunity(search: string): void {
    this.service.getSaleForOpportunity(search).pipe(
      map(response => response.data)
    ).subscribe(
      data => {
        this.salesForOpportunities.next(data);
      },
      error => {
        console.error('Error fetching sale data for opportunities', error);
      }
    );
  }

  salesForOpportunities$(): Observable<any[]> {
    return this.salesForOpportunities.asObservable();
  }

  storeFilters(filters: any): void {
    this.storedFilters = filters;
  }

  getStoredFilters(): any {
    return this.storedFilters;
  }

  setActivePage(page: number): void {
    this._activePage = page;
  }

  getActivePage(): number {
    return this._activePage;
  }

  setUpdateValue(page: number): void {
    this._updatePage = page;
  }

  getUpdateValue(): number {
    return this._updatePage;
  }

  setPageSize(size: number): void {
    this._pageSize = size;
  }

  getPageSize(): number {
    return this._pageSize;
  }

  setSortDir(sortOrder: string): void{
    this._sortOrder = sortOrder;
  }

  getSortDir(): string{
    return this._sortOrder;
  }

  setSortBy(sortBy: string): void{
    this._sortBy = sortBy;
  }

  getSortBy(): string{
    return this._sortBy;
  }

  clearFilters(): void {
    this.storedFilters = null;
  }
  getChangeLogs(pageSettings: PageSettings = DefaultTaskChangeLogsPageSettings, saleId: number): void {
    const request: ChangeLogsApiRequest = {
      id: saleId,
      entityName: 'Sales',
      pageNumber: pageSettings.activePage,
      pageSize: pageSettings.pageSize,
      sort: pageSettings.sort,
      sortBy: pageSettings.sortBy,
    };

    this.service.getSaleChangeLogs(request)
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(saleHistory => new ChangeLogsModel(saleHistory))
        }))
      )
      .subscribe(response => {
        this.changeLogsSubject.next(response);
      });
  }
}



