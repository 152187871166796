import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { startWith, switchMap, takeUntil, filter } from 'rxjs/operators';
import { LinkItemStateService } from '@shared/components/link-item/link-item.state.service';
import { of } from 'rxjs';
import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import {
  ChosenLinkModel,
  LinkModel,
  SearchAccountOptionsModel,
  SearchConversationOptionsModel,
  SearchNoteOptionsModel,
} from '@shared/models';
import { LinkType } from '@shared/enums';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { PermissionStringEnum } from '@shared/enums';
import { EcmStateService } from 'app/modules/content-management/ecm/ecm.state.service';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MemberProspectModel } from '@shared/models/member-prospect.model';


@Component({
  selector: 'app-link-item',
  templateUrl: './link-item.component.html',
  styleUrls: ['./link-item.component.scss'],
})
export class LinkItemComponent
  extends autoUnsubscribeMixin()
  implements OnInit, OnChanges {
  // checking and collecting current type
  LinkType = LinkType;
  currentLink: LinkType;
  // min length to trigger autocomplete request
  // collections of different array of links
  chosenLinks: ChosenLinkModel[] = [];
  linkMemberList: number[] = [];
  linkMemberProspectList: MemberProspectModel[] = [];
  members: ChosenLinkModel[] = [];
  membersProspects: ChosenLinkModel[] = [];
  linkConversationList: string[] = [];
  linkFilesList: string[] = [];
  linkTaskList: string[] = [];
  linkAccountsList: string[] = [];
  linkNotesList: string[] = [];
  linkSaleList: string[] = [];
  linkOpportunityList: string[] = [];
  filteredLinks: SearchMemberProspectOptionsModel[] = [];


  // helpers
  notFirstChange = false;
  isLongLinksList = false;
  counter: number;
  // permissions
  permissionConversation = PermissionStringEnum.ViewConversations;
  permissionTask = PermissionStringEnum.ViewAllTasks;
  permissionNote = PermissionStringEnum.ViewNotes;
  permissionAccount = PermissionStringEnum.ViewAccounts;
  permissionSales = PermissionStringEnum.ViewAllSales;

  filesWithAccess = null;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  form = new FormGroup({
    members: new FormControl(),
    membersProspects: new FormControl(),
    accounts: new FormControl(),
    notes: new FormControl(),
    tasks: new FormControl(),
    conversations: new FormControl(),
    sale: new FormControl(),
    opportunity: new FormControl()
  });

  constructor(
    private state: LinkItemStateService,
    private ecmState: EcmStateService
  ) {
    super();
    this.counter = this.chosenLinks.length;
  }

  @Input() links: string[];
  @Input() preselectedMembers: SearchMemberOptionsModel[];
  @Input() preselectedMembersProspects: SearchMemberProspectOptionsModel[];
  @Input() preselectedAccounts: SearchAccountOptionsModel[];
  @Input() preselectedNotes: SearchNoteOptionsModel[];
  @Input() preselectedSales: SearchNoteOptionsModel[];
  @Input() preselectedConversation: SearchConversationOptionsModel;
  @Input() readOnly: boolean;
  @Input() memberDocument = false;
  @Input() selectedLinks: LinkModel[];
  @Output() chosenInstances: EventEmitter<SearchMemberOptionsModel[]> =
    new EventEmitter<SearchMemberOptionsModel[]>();
  @Output() disabledForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  members$ = this.form.get('members')?.valueChanges.pipe(
    startWith(''),
    switchMap((search) => {
      if (
        typeof search === 'string' &&
        search.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedMembers({
          search,
          EnableProspectSearch: false,
          pageSize: 3,
          sortBy: 'ViewedDate',
          sort: 'desc',
          recentProfilesOnly: false,
          excludedMemberIds: this.linkMemberList,
        });
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.members$();
    })
  );

  membersProspects$ = this.form.get('membersProspects')?.valueChanges.pipe(
    startWith(''),
    switchMap((search) => {
      if (
        typeof search === 'string' &&
        search.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedMembers({
          search,
          EnableProspectSearch: true,
          pageSize: 3,
          sortBy: 'ViewedDate',
          sort: 'desc',
          recentProfilesOnly: false,
          excludedMemberIds: this.linkMemberList,
        });
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.members$();
    })
  );


  sale$ = this.form.get('sale').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      if (
        typeof name === 'string' &&
        name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSalesfor(
          name

        );
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.sale$();
    })
  );


  conversations$ = this.form.get('conversations').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      const array = [];
      if (
        typeof name === 'string' &&
        name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedConversations({
          search: name,
          pageSize: 3,
          // memberIds: this.linkMemberList,
          memberIds: [...this.getMembers()],
          memberProspectIds: JSON.stringify(this.linkMemberProspectList),
          excludedConversationIds: this.linkConversationList,
          ProspectIds: [...this.getProspect()]
        });
      }
      else {
        if (this.notFirstChange) {
          this.getDefaultConversationSet();
        }
      }
      return this.state.conversations$();
    })
  );

  opportunity$ = this.form.get('opportunity').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      const array = [];
      if (
        typeof name === 'string' &&
        name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedOpportunity({
          search: name,
          pageSize: 3,
          // memberIds: this.linkMemberList,
          memberIds: [...this.getMembers()],
          memberProspectIds: JSON.stringify(this.linkMemberProspectList),
          excludedConversationIds: this.linkOpportunityList,
          ProspectIds: [...this.getProspect()]
        });
      }
      else {
        if (this.notFirstChange) {
          this.getDefaultOpportunitySet();
        }
      }
      return this.state.opportunity$();
    })
  );


  tasks$ = this.form.get('tasks').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      if (typeof name === 'string') {
        this.notFirstChange = true;
        this.state.getSearchedTasks({
          search: name,
          pageSize: 5,
          memberIds: this.linkMemberList,
          memberProspectIds: JSON.stringify(this.linkMemberProspectList),
          excludedTaskIds: this.linkTaskList,
          sortBy: 'CreatedDate',
          sort: 'desc',
        });
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.tasks$();
    })
  );

  notes$ = this.form.get('notes').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      if (
        typeof name === 'string' &&
        name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedNotes({
          search: name,
          pageSize: 5,
          memberIds: this.linkMemberList,
          memberProspectIds: JSON.stringify(this.linkMemberProspectList),
          excludedNoteIds: this.linkNotesList,
          sortBy: 'Created',
          sort: 'desc',
        });
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.notes$();
    })
  );

  accounts$ = this.form.get('accounts').valueChanges.pipe(
    startWith(''),
    switchMap((name) => {
      if (
        typeof name === 'string' &&
        name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch
      ) {
        this.notFirstChange = true;
        this.state.getSearchedAccounts(
          {
            search: name,
            pageSize: 3,
            memberIds: this.linkMemberList,
            memberProspectIds: JSON.stringify(this.linkMemberProspectList),
            excludedAccountIds: this.linkAccountsList,
          },
          this.members
        );
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.accounts$();
    })
  );

  ngOnInit(): void {
    if (this.preselectedMembers) {
      this.preselectedMembers.forEach((member) => {
        this.addInstance(member);
      });
    }
    if (this.preselectedMembersProspects) {
      this.preselectedMembersProspects.forEach((membersProspects) => {
        if (membersProspects) {
          this.addInstance(membersProspects);
        }
      });
    }
    if (this.preselectedAccounts) {
      this.preselectedAccounts.forEach((item: SearchAccountOptionsModel) => {
        if(item){
          this.addInstance(item);
        }
      });
    }

    if (this.preselectedSales) {
      this.preselectedSales.forEach((item: SearchNoteOptionsModel) => {
        if(item){
          this.addInstance(item);
        }
      });
    }


    if (this.preselectedNotes) {
      this.preselectedNotes.forEach((item: SearchNoteOptionsModel) => {
        if(item){
          this.addInstance(item);
        }
      });
    }

    if (this.preselectedConversation) {
      this.addInstance(this.preselectedConversation);
    }

    if (this.links?.includes(LinkType.conversation)) {
      this?.getDefaultConversationSet();
    }


    // if (this.links?.includes(LinkType.sale)) {
    //   this?.getDefaultSalesSet('');
    // }

    this.isLongLinksList = this.links?.length > 3;

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLinks) {
      this.clearLinks();
      this.handleSelectedLinks();
      this.checkLinksLimit();
    }
  }

  getDefaultConversationSet(): void {
    this.state.getSearchedConversations({
      pageSize: 5,
      // memberIds: this.linkMemberList,
      sort: 'desc',
      excludedConversationIds: this.linkConversationList,
      memberIds: [...this.getMembers()],
      memberProspectIds: JSON.stringify(this.linkMemberProspectList),
      ProspectIds: [...this.getProspect()]
    });
  }


  // getDefaultSalesSet(searchParam: string): void {
  //   this.state.getSalesfor(searchParam).subscribe(
  //     sales => {
  //       console.log('Sales Data:', sales);
  //     },
  //     error => {
  //       console.error('Error fetching sales data:', error);
  //     }
  //   );
  //   }
  getDefaultOpportunitySet(): void {
    this.state.getSearchedOpportunity({
      pageSize: 5,
      // memberIds: this.linkMemberList,
      sort: 'desc',
      excludedConversationIds: this.linkConversationList,
      memberIds: [...this.getMembers()],
      memberProspectIds: JSON.stringify(this.linkMemberProspectList),
      ProspectIds: [...this.getProspect()]
    });
  }

  openLink(link): void {
    this.currentLink = this.currentLink === link ? '' : link;
  }

  checkLink(link): boolean {
    return this.links.includes(link);
  }

  addInstance(value): void {
    if (this.currentLink) {
      if (value.type === 'member' || value.type === 'Member') {
        value.type = 'member';
      } else if (value.type === 'prospect' || value.type === 'Prospect') {
        value.type = 'prospect';
      } else {
        value.type = this.currentLink;
      }
    }
    this.updateListsWithExcludedValues(value);
    if (Array.isArray(value)) {

      this.chosenLinks.push(...value);
    } else {

      this.chosenLinks.push(value);
    }
    this.validateAll();
    this.resetForm();
  }

  resetForm(): void {
    this.currentLink = undefined;
    this.form.reset();
  }

  checkLinksLimit(): void {
    this.counter = this.chosenLinks.length;
  }

  validateAll(): void {
    this.checkLinksLimit();
    this.chosenInstances.emit(this.chosenLinks);
  }

  removeFromChosenLinks(value): void {
    this.chosenLinks = this.chosenLinks?.filter(
      (item) => item?.ref !== value?.ref
    );
    this.linkMemberList = this.linkMemberList.filter(
      (item) => item !== parseInt(value.ref, 10)
    );
    this.linkMemberProspectList = this.linkMemberProspectList.filter(
      (item) => item.ref !== (value.ref).toString()
    );
    this.linkConversationList = this.linkConversationList.filter(
      (item) => item !== value.ref
    );
    this.linkOpportunityList = this.linkOpportunityList.filter(
      (item) => item !== value.ref
    );
    this.linkTaskList = this.linkTaskList.filter((item) => item !== value.ref);
    this.linkAccountsList = this.linkAccountsList.filter(
      (item) => item !== value.ref
    );
    this.linkNotesList = this.linkNotesList.filter(
      (item) => item !== value.ref
    );
    this.linkSaleList = this.linkSaleList.filter(

      (item) => item !== value.ref

    );
    this.validateAll();
  }

  setFileDisabledStates(): void {
    if (this.linkFilesList.length) {
      this.ecmState
        .checkFilesAccess(this.linkFilesList)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.filesWithAccess = response.data;

          const files = this.chosenLinks.filter(
            (item) => item.type === this.LinkType.file
          );

          if (!this.filesWithAccess.length) {
            files.forEach((item) => (item.disabled = true));
          }

          const list = [];
          this.filesWithAccess.forEach((item) => {
            list.push(
              files.find((value) => {
                return item.id === +value.ref;
              })
            );
          });

          const disabledFiles = files.filter((x) => !list.includes(x));

          disabledFiles.forEach((item) => (item.disabled = true));
        });
    }
  }

  private handleSelectedLinks(): void {
    this.chosenLinks = this.selectedLinks.map<ChosenLinkModel>((link) => {
      const chosenLink: any = {
        ref: link.ref,
        type: this.getLinkType(link),
        value: this.getLinkValue(link),
        accountNumber: link.accountNumber,
        cellPhone : link.cellPhone,
      };

      this.updateListsWithExcludedValues(chosenLink);
      return chosenLink;
    });
    this.setFileDisabledStates();
  }

  private clearLinks(): void {
    this.resetForm();

    this.chosenLinks = [];
    this.linkMemberList = [];
    this.linkMemberProspectList = [];
    this.linkConversationList = [];
    this.linkOpportunityList = [];
    this.linkAccountsList = [];
    this.linkTaskList = [];
    this.linkNotesList = [];
  }

  private updateListsWithExcludedValues(value: ChosenLinkModel): void {

    if (!value.ref && value.id) {
      value.ref = value.id.toString();
    }

    switch (value?.type) {
      case LinkType.member:
        this.members.push(value);
        this.linkMemberList.push(+value.ref);
        this.pushMemberProspectModel(value.ref, value.type === 'member' ? false : true,value.cellPhone);
        break;
      case LinkType.prospect:
        this.members.push(value);
        this.linkMemberList.push(+value.ref);
        this.pushMemberProspectModel(value.ref, value.type === 'prospect' ? true : false,value.cellPhone);
        break;
      case LinkType.file:
        this.linkFilesList.push(value.ref);
        break;
      case LinkType.conversation:
        this.linkConversationList.push(value.ref);
        break;
        case LinkType.opportunity:
          this.linkOpportunityList.push(value.ref);
          break;
      case LinkType.account:
        this.linkAccountsList.push(value.ref);
        break;
      case LinkType.note:
        this.linkNotesList.push(value.ref);
        break;
      case LinkType.task:
        this.linkTaskList.push(value.ref);
        break;
      case LinkType.sale:
        this.linkSaleList.push(value.ref);
        break;
    }
  }

  private pushMemberProspectModel(ref: string, isProspectInfo: boolean, cellPhone: string): void {
    const memberProspectModel: MemberProspectModel = {
      ref: ref.toString(),
      value: '', // Assuming value is a string
      isProspectInfo: isProspectInfo ? isProspectInfo : false, // Optional property
      cellPhone,
    };
    this.linkMemberProspectList.push(memberProspectModel);
  }

  private getLinkType(link: LinkModel): string {
    switch (link?.type) {
      case 'Member':
        return LinkType.member;
      case 'Prospect':
        return LinkType.prospect;
      case 'Conversation':
        return LinkType.conversation;
      case 'Task':
        return LinkType.task;
      case 'Account':
        return LinkType.account;
      case 'Sale':
        return LinkType.sale;
      case 'File':
        return LinkType.file;
      case 'Note':
        return LinkType.note;
      default:
        return link.type;
    }
  }

  private getLinkValue(link: LinkModel): string {
    switch (link.type) {
      case 'Member':
      case 'member':
        return link.organizationName === undefined && link.firstName === undefined && link.lastName === undefined ? link.value
          : link.organizationName
            ? link.organizationName
            : `${link.firstName} ${link.lastName}`;
      case 'Prospect':
      case 'prospect':
        return link.organizationName
          ? link.organizationName
          : `${link.firstName} ${link.lastName}`;
      case 'Conversation':
      case 'conversation':
        return link.conversationSubject;
        case 'Opportunity':
          case 'opportunity':
            return link.opportunitySubject;
      case 'Task':
      case 'task':
        return link.taskDescription;
      case 'Note':
      case 'note':
        return link.noteDescription;
      case 'File':
      case 'file':
        return link.fileName;
      case 'Account':
      case 'account':
        return link.accountName === undefined && link.value ? link.value : link.accountName ? link.accountName : 'N/A';
      case 'Sale':
      case 'sale':
        return link.saleName ;
    }
  }

  get isMemberLinked(): boolean {
    return !!(this.chosenLinks.length && this.linkMemberList.length);
  }



  getMembers(): any {
    const members = [];
    this.chosenLinks.forEach((data: any) => {
      if (data?.isProspectInfo === false || data?.type === 'member') {
        members.push(data?.ref);
      }
    });
    return members;
  }


  getProspect(): any {
    const prospect = [];
    this.chosenLinks.forEach((data: any) => {
      if (data?.isProspectInfo === true || data?.type === 'prospect') {
        prospect.push(data.ref);
      }
    });
    return prospect;
  }

}
