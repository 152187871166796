import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmailTemplateDto } from '@core/services/dto';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';
import { LinkModel, SearchEmailTemplatesOptionsModel } from '@shared/models';
import { SearchSMSTemplatesOptionsModel } from '@shared/models/search.sms-templates.options.model';
import { DATE_WITH_TIME_FORMAT, formatDateToLocalSimple } from '@shared/utils';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnChanges, OnInit {
  @Input() templates: EmailTemplateDto[] | SearchEmailTemplatesOptionsModel[];
  @Input() templateSms: SMSTemplateDto[] | SearchSMSTemplatesOptionsModel[];
  @Input() mode: string;
  isSendDisabled: boolean;
  @Input() memberError = false;
  @Input() TemplateType: string;
  @Input() selectedLinks = [];
  memberlinks: any;
  isSentclicked = false;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendEmail: EventEmitter<EmailTemplateDto | SMSTemplateDto> = new EventEmitter<EmailTemplateDto | SMSTemplateDto>();
  ngOnInit(): void {
    this.setDefaultChecked();
    this.updateSendButtonState();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates && changes.templates.currentValue) {
      if (this.templates && this.templates.length && this.mode === 'view') {
        if (this.TemplateType === 'Email') {
          if (this.templates && Array.isArray(this.templates)) {
            (this.templates as EmailTemplateDto[]).forEach((item: EmailTemplateDto) => {
              item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
            });
          }
        } else if (this.TemplateType === 'SMS') {
          if (this.templateSms && Array.isArray(this.templateSms)) {
            (this.templateSms as SMSTemplateDto[]).forEach((item: SMSTemplateDto) => {
              item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
            });
          }
        }
      }
    }
  }

  send(event: EmailTemplateDto | SMSTemplateDto): void {



    this.memberlinks.forEach(obj => {
      obj.isEmailSent = false;
    });

    const checkedMembers = this.memberlinks.filter(item => item.checked);

    this.isSentclicked = true;

    this.memberlinks = this.memberlinks.map(item => {
      if (item.checked) {
        item.isEmailSent = true;
      }
      return item;
    }).filter(item => item.isEmailSent && item.checked);

    // Add checkedMembers to the event object
    const updatedEvent = {
      ...event,
      checkedMembers
    };

    this.sendEmail.emit(updatedEvent);
  }

  remove(event: EmailTemplateDto | SMSTemplateDto): void {
    this.delete.emit(event);
  }
  onSelectionChange(): void {
    this.updateSendButtonState();
  }
  onCheckboxChange(item: any) {
    item.checked = !item.checked;
    this.updateSendButtonState();
  }
  updateSendButtonState() {
    if (this.TemplateType === 'Email') {
      this.isSendDisabled = this.selectedLinks.every(link => !link.checked);
    }
  }

  private setDefaultChecked(): void {
    const MemberListWithEmail = this.selectedLinks.filter(
      (item) => (item.type === 'Member' || item.type === 'Prospect' ) && item.isEmailSent === true
    );
    if (MemberListWithEmail.length > 0) {
      this.memberlinks = MemberListWithEmail;
      this.isSentclicked = true;
      this.isSendDisabled = true;
      this.memberlinks.forEach(item => {
        item.checked = true;
      });
    }
    else {
      this.memberlinks = this.selectedLinks.filter(
        (item) => ['Member','Prospect'].includes(item.type)
      );
      if (this.memberlinks.length > 0) {
        this.memberlinks[0].checked = true;
      }
    }
  }
}
