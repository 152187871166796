import { Injectable } from '@angular/core';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { EcmApiService } from '@core/services/api.services';
import { Observable, Subject } from 'rxjs';
import { EcmFilesDto, EcmFoldersDto, FolderDto, PaginatedResponseDto, ProductTypeDto } from '@core/services/dto';
import { filter, map, takeUntil } from 'rxjs/operators';
import { EcmFileDetailsModel, EcmFilesModel, EcmFolderModel } from '@shared/models';
import { DEFAULT_DATE_FORMAT, formatDateToLocalSimple, saveAsBlob } from '@shared/utils';

@Injectable({
  providedIn: 'any'
})
export class EcmStateService extends autoUnsubscribeMixin() {
  private data: Subject<PaginatedResponseDto<EcmFolderModel>> = new Subject<PaginatedResponseDto<EcmFolderModel>>();
  public data$: Observable<PaginatedResponseDto<EcmFolderModel>> = this.data
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  private files: Subject<PaginatedResponseDto<EcmFilesModel>> = new Subject<PaginatedResponseDto<EcmFilesModel>>();
  public files$: Observable<PaginatedResponseDto<EcmFilesModel>> = this.files
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  private types: Subject<PaginatedResponseDto<ProductTypeDto>> = new Subject<PaginatedResponseDto<ProductTypeDto>>();
  public types$: Observable<PaginatedResponseDto<ProductTypeDto>> = this.types
    .asObservable()
    .pipe(
      filter(x => !!x)
    );
    private applications: Subject<PaginatedResponseDto<ProductTypeDto>> = new Subject<PaginatedResponseDto<ProductTypeDto>>();
    public application$: Observable<PaginatedResponseDto<ProductTypeDto>> = this.applications
      .asObservable()
      .pipe(
        filter(x => !!x)
      );
  private subTypes: Subject<PaginatedResponseDto<ProductTypeDto>> = new Subject<PaginatedResponseDto<ProductTypeDto>>();
  public subTypes$: Observable<PaginatedResponseDto<ProductTypeDto>> = this.subTypes
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  private file: Subject<EcmFileDetailsModel> = new Subject<EcmFileDetailsModel>();

  public file$: Observable<EcmFileDetailsModel> = this.file
    .asObservable()
    .pipe(
      filter(x => !!x)
    );

  constructor(private service: EcmApiService) {
    super();
  }

  async getTableData(request, isAdmin): Promise<void> {
    const response: PaginatedResponseDto<EcmFoldersDto> = await this.service.getTableData(request).toPromise();
    const transformedResponse = {
      ...response,
      data: response.data.map((item: EcmFoldersDto) => new EcmFolderModel(item, isAdmin))
    };
    this.data.next(transformedResponse);
  }

  getFiles(request): void {
    this.service.getFiles(request).pipe(
      map(
        (response: PaginatedResponseDto<EcmFilesDto>) => {
          return {
            ...response,
            data: response.data.map((item: EcmFilesDto) => new EcmFilesModel(item))
          };
        }
      )).subscribe(response => {
        this.files.next(response);
      });
  }

  checkFilesAccess(ids): Observable<PaginatedResponseDto<EcmFilesDto>> {
    return this.service.getFiles({ includedFileIds: ids });
  }

  getFilesSummary(memberId): Observable<string> {
    return this.service.getFilesSummary(memberId).pipe(
      map((response => formatDateToLocalSimple(response.dateUploaded, DEFAULT_DATE_FORMAT)))
    );
  }

  getFolder(id): Observable<FolderDto> {
    return this.service.getFolder(id);
  }

  deleteInstance(instance, final): Observable<any> {
    return this.service.deleteInstance(instance, final);
  }
  archiveFile(instance): Observable<any> {
    return this.service.archiveFile(instance);
  }
  restoreFile(instance): Observable<any> {
    return this.service.restoreFile(instance);
  }

  deleteFiles(includedFiles): Observable<any> {
    return this.service.deleteFiles(includedFiles);
  }

  deleteFolder(ids): Observable<any> {
    return this.service.deleteFolder(ids);
  }

  deleteFolders(includedFoldersIds): Observable<any> {
    return this.service.deleteFolders(includedFoldersIds);
  }

  getFile(id): void {
    this.service.getFile(id).pipe(
      map(file => new EcmFileDetailsModel(file))
    ).subscribe(file => this.file.next(file));
  }

  getTypes(): void {
    this.service.getTypes().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(types => this.types.next(types));
  }

  getSubTypes(id): void {
    this.service.getSubTypes(id).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(subTypes => this.subTypes.next(subTypes));
  }

  downloadFile(ids): void {
    this.service.downloadFile(ids).subscribe((response) => {
      saveAsBlob(response?.data.body, response?.name, response?.data.body.type);
    });
  }
    getApplicationId(request): void {
    this.service.getApplicationId().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(types => this.applications.next(types));
  }
}
