import { SelectOption } from '@shared/interfaces';
import { TaskStatusEnum, TaskTypeEnum } from '@shared/enums';
import { PageSettings } from '../change-logs/models/change-logs.models';

export const TypeOptions: SelectOption<string>[] = [
  {
    value: TaskTypeEnum[TaskTypeEnum.General],
    label: 'General'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Email],
    label: 'E-mail'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Phone],
    label: 'Phone'
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.FollowUp],
    label: 'Follow Up'
  },
 {
    value: TaskTypeEnum[TaskTypeEnum.SMS],
    label: 'SMS'
  }

];

export const StatusOptions: SelectOption<string>[] = [
  {
    value: TaskStatusEnum[TaskStatusEnum.Opened],
    label: 'Opened'
  },
  {
    value: TaskStatusEnum[TaskStatusEnum.Completed],
    label: 'Completed'
  },
  {
    value: TaskStatusEnum[TaskStatusEnum.Reopened],
    label: 'Reopened'
  }
];

export const PriorityOptions: SelectOption<string>[] = [
  {
    value: 'Low',
    label: 'Low'
  },
  {
    value: 'Medium',
    label: 'Medium'
  },
  {
    value: 'High',
    label: 'High'
  },
];
export const DefaultTaskChangeLogsPageSettings: PageSettings = {
  pageSize: 5,
  activePage: 0,
  sortBy: 'created',
  sort: 'desc'
};
