import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InteractionUrls} from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { InteractionApiRequest } from '@shared/models/requests/interaction.request.dto';
import { InteractionDto, PaginatedResponseDto } from '@core/services/dto';
import { InteractionModel } from '@shared/models/interaction.model';
import { InteractionUpdateApiRequest } from '@shared/models/requests/interaction-update.request.dto';
import { InteractionCreateInterface } from '@shared/models';
import { ChangeLogsDto } from '../dto/change-logs.dto';
import { ChangeLogsApiRequest } from '@shared/models/requests/changeLogs.request.dto';

@Injectable({
  providedIn: 'any'
})
export class InteractionApiService {

  constructor(private api: ApiClient) {
  }

  getInteractions(request: InteractionApiRequest): Observable<PaginatedResponseDto<InteractionDto>> {
    return this.api.get<PaginatedResponseDto<InteractionDto>>(InteractionUrls.interactions, request);
  }
  setInteraction(interaction: InteractionCreateInterface): Observable<InteractionModel> {
    return this.api.post<InteractionModel>(InteractionUrls.interactions, interaction);
  }

  getInteraction(id: number): Observable<InteractionDto> {
    return this.api.get<InteractionDto>(InteractionUrls.interaction(id));
  }

  updateInteraction(interactionId: number, request: InteractionUpdateApiRequest): Observable<InteractionDto> {
    return this.api.put<InteractionDto>(InteractionUrls.interaction(interactionId), request);
  }
  getInteractionChangeLogs(request: ChangeLogsApiRequest): Observable<PaginatedResponseDto<ChangeLogsDto>> {
    return this.api.get<PaginatedResponseDto<ChangeLogsDto>>(InteractionUrls.interactionsHistory, request);
  }
}
