export interface PersonInfoInterface {
  FirstName: string;
  LastName: string;
  MemberNumber: string;
  Email: string;
  CUEmail: string;
  CUCurrentUser: string;
  Phone: string;
  ref: string;
  AllMemberFirstName: string;
}
