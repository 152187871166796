import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { AddEmailTemplateStateService } from '@shared/components/add-email-template/add-email-template.state.service';
import { SearchEmailTemplatesOptionsModel } from '@shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import { startWith, switchMap } from 'rxjs/operators';
import { InputAutocompleteComponent } from '@shared/components/input-autocomplete/input-autocomplete.component';
import { EmailTemplateDto } from '@core/services/dto';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';

@Component({
  selector: 'app-add-email-template',
  templateUrl: './add-email-template.component.html',
  styleUrls: ['./add-email-template.component.scss']
})
export class AddEmailTemplateComponent implements OnChanges, OnDestroy {
  constructor(
    private state: AddEmailTemplateStateService
  ) {
  }
  emailTemplate: any;
  notFirstChange: boolean;
  selected: SearchEmailTemplatesOptionsModel[] = [];
  excludedIds: number[] = [];
  excludedName: string[] = [];

  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  @ViewChild(InputAutocompleteComponent) inputAutocompleteComponent: InputAutocompleteComponent;
  @Input() preselected: EmailTemplateDto[];
  @Output() chosenEmail: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() chosenEmailTemplateName: EventEmitter<string[]> = new EventEmitter<string[]>();
  form = new FormGroup({
    emailTemplate: new FormControl()
  });

  templates$ = this.form.get('emailTemplate').valueChanges.pipe(
    startWith(''),
    switchMap(name => {
      if (typeof name === 'string' && name.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.notFirstChange = true;
        this.state.getTemplates(name, this.excludedIds);
      } else {
        if (this.notFirstChange) {
          return of([]);
        }
      }
      return this.state.templates$();
    })
  );

  ngOnChanges(): void {
      if (this.preselected && this.preselected.length) {
        this.selected = this.preselected.map(item => new SearchEmailTemplatesOptionsModel(item));
      }
      this.selected.forEach(item => {
        this.excludedIds.push(item.ref);
       this.excludedName.push(item.label);
       this.chosenEmailTemplateName.emit(this.excludedName);
      });
  }

  addInstance(event): void {
    this.inputAutocompleteComponent.clear();
    this.selected.push(event);
    this.excludedIds.push(event.ref);
    this.excludedName.push(event.label);
    this.chosenEmailTemplateName.emit(this.excludedName);
    this.chosenEmail.emit(this.excludedIds);
  }

  remove(event): void {
    this.excludedIds.splice(this.excludedIds.findIndex( item => item === event.ref), 1);
    this.selected.splice(this.selected.findIndex( item => item.ref === event.ref), 1);
    this.chosenEmail.emit(this.excludedIds);
    this.excludedName.splice(this.excludedName.findIndex( item => item === event.label), 1);
    this.chosenEmailTemplateName.emit(this.excludedName);
  }

  ngOnDestroy(): void {
    this.selected = [];
    this.excludedIds = [];
    this.preselected = [];
  }
}
